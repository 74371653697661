<script setup lang="ts">
//dependencies
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";

//store
import { useOrderStore, useLoaderStore, useDispensingStore } from "@/store";
import CompressedPhotoUpload from "@/components/common/CompressedPhotoUpload.vue";

//services
import {
	createTaskValue,
	updateTaskState,
} from "@/services/task.service";

//imports
import ChevronRight from "@/assets/chevron-right.svg?url";
import $toast from "@/utils/toast";
import { ImageData } from "@/utils/types";
import { Task_State_Enum } from "@/sdk";
import { TYPE } from "vue-toastification";
import { backBtnMessage } from "@/services/general";
import { delay } from "@/utils/general";

const router = useRouter();

const orderStore = useOrderStore();
const loaderStore = useLoaderStore();
const dispensingStore = useDispensingStore();

const totalizerReading = ref<number>();
const url = ref<ImageData | null>();
const imageSrcFile = ref<File | null>();

function chooseImage(blob: File) {
	imageSrcFile.value = blob;
}
function getReading(reading: number) {
	totalizerReading.value = reading;
}

function removeImage() {
	url.value = null;
	imageSrcFile.value = null;
}

const taskActionResponse = ref<boolean>(false);
const nextStep = async () => {
	if (!totalizerReading.value) {
		return alert("Please enter the Start Totalizer Reading");
	}
	try {
		orderStore.setTotalizerBeforeDuringFirstAssetDispense(
			totalizerReading.value,
		);
		loaderStore.toggleLoader({type: 'common', state: true});

		const currentAssetForDispense = {
			...orderStore.getCurrentAssetForDispense,
		};
		orderStore.setCurrentAssetForDispense({
			...currentAssetForDispense,
			totalizerBefore: {
				reading: totalizerReading.value,
				storeURL: url.value?.storeUrl,
			},
		});
		if (orderStore.getCurrentOrder.category === "DELIVERY") {
			const response = await createTaskValue({
				is_active: true,
				key: "TOTALIZER_BEFORE_READING",
				url: url.value ? `${url.value?.storeUrl}` : "",
				value: `${totalizerReading.value}`,
				task_id: `${orderStore.getCurrentOrder?.id}`,
				customer_asset_id: `${currentAssetForDispense?.id}`,
				session_id: dispensingStore.sessionId,
			});
			taskActionResponse.value =
				typeof response === "boolean" ? response : false;
		} else {
			const response = await createTaskValue({
				is_active: true,
				key: "TOTALIZER_BEFORE_READING",
				url: url.value ? `${url.value?.storeUrl}` : "",
				value: `${totalizerReading.value}`,
				task_id: `${orderStore.getCurrentOrder?.id}`,
				vehicle_id: `${currentAssetForDispense?.id}`,
				session_id: dispensingStore.sessionId,
				customer_asset_id: `${currentAssetForDispense?.id}`,
			});
			taskActionResponse.value =
				typeof response === "boolean" ? response : false;
		}

		if (typeof taskActionResponse.value === "string") {
			$toast(taskActionResponse.value, TYPE.ERROR);
		} else {
			if (orderStore.getCurrentOrder?.state === "ARRIVED") {
				await updateTaskState(
					orderStore.getCurrentOrder.id,
					Task_State_Enum.Dispensing,
				);
			}
			loaderStore.toggleLoader({type: 'common', state: false});
			// stepStore.removeCurrentStepDetails(true);
			orderStore.setTotalizerBeforeReading(totalizerReading.value);
			router.push("/totalizer-after-manual");
		}
	} catch (e) {
		$toast("Please try again", TYPE.ERROR);
		loaderStore.toggleLoader({type: 'common', state: false});
		throw new Error("Error proceeding", { cause: e });
	} finally {
		loaderStore.toggleLoader({type: 'common', state: false});
	}
};

onMounted(() => {
	window.addEventListener("popstate", backBtnMessage);
});

onUnmounted(async () => {
	await delay(100);
	window.removeEventListener("popstate", backBtnMessage);
})
</script>

<template>
	<div
		class="container flex flex-col items-center justify-start min-h-[calc(100vh-64px)] space-y-4">
		<CompressedPhotoUpload
			:choose-image="chooseImage"
			:button="false"
			name-one="Start Totalizer"
			:reading-one-top="true"
			:get-reading="getReading"
			:remove-image="removeImage" />
		<div class="flex flex-col w-full pb-4">
			<button
				type="button"
				class="btn btn-green text-xl"
				@click.prevent="nextStep">
				Proceed
				<img
					:src="ChevronRight"
					alt="Chevron_Right"
					class="absolute right-6" />
			</button>
		</div>
	</div>
</template>

<style scoped></style>
