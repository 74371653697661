import {
	Customer_Order_Item_Safety_Checklist_Results_Insert_Input,
	Task_State_Enum,
	CreateTaskValueInput,
	Task_Cancellation_Reasons_Insert_Input,
	Task_Value,
} from "@/sdk";
import client from "./client";
import { useDispensingStore, useOrderStore } from "@/store";

/**
 * @description Update the status of a task
 * @param taskId UUID of the task whose status is to be updated
 * @param state Updated status of the task
 */
export const updateTaskState = async (
	taskId: string,
	state: Task_State_Enum,
) => {
	return await (
		await client
	)?.updateTaskState({
		id: taskId,
		state,
	});
};

/**
 * @description Create a new task value entry based on the current dispensing stage
 * @param object Task value object
 * @returns Promise
 */
export const createTaskValue = async (
	object: CreateTaskValueInput,
) => {
	return await (
		await client
	)?.createTaskValue({
		object,
	});
};

export const getTaskValueByCustomerAssetId = async (
	taskId: string,
	customerAssetId: string,
) => {
	return (
		await (
			await client
		)?.getTaskValueForCustomerAsset({
			customerAssetId,
			taskId,
		})
	)?.fetchTaskValueForCustomerAsset;
};

/**
 * @description Create a new entry for health and safety checklist form
 */
export const addCustomerItemSafety = async (
	objects: Customer_Order_Item_Safety_Checklist_Results_Insert_Input[],
) => {
	try {
		await (
			await client
		)?.addCustomerItemSaftyCheckListResults({ objects });
		return true;
	} catch (error) {
		throw new Error("Error updating health and safety form");
	}
};

export const addTaskCancelReason = async (
	object: Task_Cancellation_Reasons_Insert_Input,
) => {
	try {
		return await (
			await client
		)?.addTaskCancellationReason({ object: object });
	} catch (error) {
		throw new Error("Error Inserting Cancel Reason");
	}
};

export const addTotalizerBeforeReading = async () => {
	const orderStore = useOrderStore();
	const dispensingStore = useDispensingStore();
	const taskValues = orderStore.getCurrentOrder?.task_values;

	const isTotalizerAfter = taskValues.some(
		(task: Task_Value) => task.key === "TOTALIZER_AFTER_READING",
	);

	if (!isTotalizerAfter) return;

	const validTotalizerAfterReadings = taskValues
		.filter((task: Task_Value) => task.session_id !== undefined)
		.sort(
			(a: Task_Value, b: Task_Value) =>
				parseInt(a.session_id!) - parseInt(b.session_id!),
		);

	// Filter to get only TOTALIZER_AFTER_READING tasks
	const lastTotalizerAfterReading =
		validTotalizerAfterReadings.filter(
			(task: Task_Value) => task.key === "TOTALIZER_AFTER_READING",
		);

	const lastReadingValue = parseFloat(
		lastTotalizerAfterReading[lastTotalizerAfterReading.length - 1]
			?.value,
	);

	if (orderStore.getCurrentOrder.category === "DELIVERY") {
		try {
			await createTaskValue({
				is_active: true,
				key: "TOTALIZER_BEFORE_READING",
				value: `${lastReadingValue}`,
				task_id: `${orderStore.getCurrentOrder?.id}`,
				customer_asset_id: `${orderStore.getCurrentAssetForDispense?.id}`,
				session_id: dispensingStore.sessionId,
			});
			orderStore.setLastTotalizerAfterAsTotalizerBefore(
				lastReadingValue,
			);
		} catch (error) {
			console.error("Failed to add step task action:", error);
		}
	}
};
