<script setup lang="ts">
//dependencies
import { ref, onMounted, onUnmounted } from "vue";

//store
import { createTaskValue } from "@/services/task.service";
import { fetchLastOdometerReading } from "@/services/order.service";
import { useOrderStore, useLoaderStore, useDispensingStore } from "@/store";

//imports
import ChevronRight from "@/assets/chevron-right.svg?url";
import $toast from "@/utils/toast";
import { TYPE } from "vue-toastification";
import { backBtnMessage } from "@/services/general";
import { delay } from "@/utils/general";
import { handleTotalizerRouting } from "@/utils/taskRoutingHelper";

const orderStore = useOrderStore();
const loaderStore = useLoaderStore();
const dispensingStore = useDispensingStore();

const odometerReading = ref<number | null>(null);
const lastOdometerReading = ref<number | undefined>();

const ODOMETER_READING_ERROR =
	"Odometer reading for this customer asset already exists";

async function nextStep() {
	// Early return if odometer reading is invalid
	if (odometerReading.value === null || undefined) {
		$toast("Please enter Odometer Reading", TYPE.WARNING);
		return;
	}

	// Show loader
	loaderStore.toggleLoader({ type: "common", state: true });

	try {
		await createTaskValue({
			is_active: true,
			key: "ODOMETER_READING",
			value: `${odometerReading.value}`,
			task_id: `${orderStore.getCurrentOrder?.id}`,
			customer_asset_id: `${orderStore?.getCurrentAssetForDispense?.id}`,
			session_id: dispensingStore.sessionId,
		});

		// Proceed with the normal flow if no errors
		await handleTotalizerRouting();

	} catch (error: any) {
		// Check if the error has a specific structure
		if (error.response.errors[0].message === ODOMETER_READING_ERROR) {
			// Execute the else block logic in case of the specific error
			$toast("Odometer reading for this asset exists", TYPE.ERROR);
			await handleTotalizerRouting();
		} else {
			$toast("An unexpected error occurred", TYPE.ERROR);
		}
	} finally {
		// Hide loader
		loaderStore.toggleLoader({ type: "common", state: false });
	}
}

const fetchingLastOdometer = ref<boolean>(true);

onMounted(async () => {
	try {
		window.addEventListener("popstate", backBtnMessage);
		const response = await fetchLastOdometerReading(
			orderStore?.getCurrentAssetForDispense?.id,
		);
		lastOdometerReading.value = parseInt(response.value);
	} catch (err) {
		$toast("Could not fetch previous odometer reading", TYPE.ERROR);
		throw new Error("Error while fetching last odometer reading", {
			cause: err,
		});
	} finally {
		fetchingLastOdometer.value = false;
	}
});

onUnmounted(async () => {
	await delay(100);
	window.removeEventListener("popstate", backBtnMessage);
});
</script>

<template>
	<div
		class="container flex flex-col items-center justify-start min-h-[calc(100vh-64px)] space-y-4">
		<div class="flex flex-col w-full py-8">
			<h1 class="text-2xl font-semibold text-center">
				Odometer Reading
			</h1>
			<p class="text-center text-gray-700">
				Please enter the odometer reading of the vehicle
			</p>
			<input
				type="number"
				min="0"
				placeholder="Type here"
				class="input input-bordered w-full mt-4"
				v-model="odometerReading" />
		</div>
		<div class="flex flex-col space-y-2 w-full pb-4 text-xl">
			<span
				class="text-center mb-2 text-xs font-semibold text-gray-700"
				v-if="fetchingLastOdometer"
				>Checking previous odometer reading, please wait</span
			>
			<span
				v-if="!fetchingLastOdometer"
				class="text-center mb-2 text-xs font-semibold"
				:class="`text-center mb-2 text-xs font-semibold ${
					lastOdometerReading &&
					odometerReading !== null &&
					odometerReading < lastOdometerReading
						? 'text-red-700'
						: 'text-green-700'
				}`"
				>Last odometer reading was
				{{ lastOdometerReading || "not recorded" }}</span
			>
			<button
				:disabled="fetchingLastOdometer"
				type="button"
				class="btn btn-green text-xl"
				@click.prevent="nextStep">
				Proceed
				<img
					:src="ChevronRight"
					alt="Chevron_Right"
					class="absolute right-6" />
			</button>
		</div>
	</div>
</template>

<style scoped></style>
