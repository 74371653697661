<script setup lang="ts">
//store
import { useOrderStore } from "@/store";

//imports

const orderStore = useOrderStore();

</script>

<template>
	<div class="w-full">
		<div class="w-full flex items-center justify-between">
			<p
				v-if="orderStore.getCurrentOrder?.category === 'DELIVERY'"
				class="font-bold underline underline-offset-4">
				Asset details
			</p>
			<p v-else class="font-bold underline underline-offset-4">
				Fillup details
			</p>
			<!-- <button
        for="my-modal"
        class="text-blue-600 font-medium border-b border-blue-500">
        Instructions
      </button> -->
		</div>

		<div class="pt-1 flex flex-col gap-y-1 w-full">
			<p class="text-sm">
				<span
					v-if="
						orderStore.getCurrentOrder?.category === 'DELIVERY'
					"
					class="font-bold">
					Asset name:
				</span>
				<span v-else class="font-bold"> Truck name: </span>
				{{
					orderStore.getCurrentAssetForDispense?.name ??
					orderStore.getCurrentOrder?.fillup_requests[0]?.vehicle_tank_type_product_variation?.vehicle_tank_type?.vehicle?.name 
					?? "NA"
				}}
			</p>
			<p
				class="text-sm"
				v-if="orderStore.getCurrentOrder?.category === 'FILL_UP'">
				<span class="font-bold"> Tank type: </span>
				{{
					orderStore.getCurrentOrder?.fillup_requests[0]
						?.vehicle_tank_type_product_variation?.vehicle_tank_type
						?.tank_type?.name || "NA"
				}}
			</p>
			<p class="text-sm">
				<span class="font-bold"> Asset number : </span>

				{{ orderStore.getCurrentAssetForDispense?.description }}
			</p>
			<p class="text-sm">
				<span
					v-if="
						orderStore.getCurrentOrder?.category === 'DELIVERY'
					"
					class="font-bold">
					Tank capacity:
				</span>
				<span v-else class="font-bold"> Dispense quantity: </span>
				{{
					orderStore.getCurrentAssetForDispense?.capacity ??
					orderStore.getCurrentOrder?.fillup_requests[0]?.quantity ??
					"NA"
				}}
				litres
			</p>
		</div>
	</div>
</template>

<style scoped></style>
