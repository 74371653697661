<script setup lang="ts">
//dependencies
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { ImageData } from "@/utils/types";

//store
import { useOrderStore, useLoaderStore, useDispensingStore } from "@/store";
import { createTaskValue } from "@/services/task.service";
import { updateCustomerOrderCustomerAssetQty } from "@/services/order.service";

import CompressedPhotoUpload from "@/components/common/CompressedPhotoUpload.vue";

//imports
import { backBtnMessage } from "@/services/general";
import { delay } from "@/utils/general";

const router = useRouter();

const orderStore = useOrderStore();
const loaderStore = useLoaderStore();
const dispensingStore = useDispensingStore();

const imageSrcFile = ref<File | null>();

const totalizerBeforeReading = ref<number>(0);

const totalizerReading = ref<number>(0);
const url = ref<ImageData | null>();

function chooseImage(blob: File) {
	imageSrcFile.value = blob;
}
function getReading(reading: number) {
	totalizerReading.value = reading;
}
function removeImage() {
	url.value = null;
	imageSrcFile.value = null;
}

const nextStep = async (e: Event) => {
	e.preventDefault();
	if (!totalizerReading.value) {
		return alert("Please enter the quantity dispensed");
	} else {
		if (totalizerReading.value !== 0) {
			orderStore.setDispensedQuantity(totalizerReading.value);
		} else {
			alert("You can't dispense 0 litres");
		}

		let filled = false;
		if (
			orderStore.getFuelDispensedTillNow +
				orderStore?.getDispensedQty >
				orderStore.getQuantityToBeDispensed &&
			orderStore.getDispensedQty !== 0
		) {
			return alert("You can't dispense more fuel than ordered.");
		}
		filled = true;

		if (filled) {
			loaderStore.toggleLoader({type: 'common', state: true});

			const completedAsset = {
				...orderStore.getCurrentAssetForDispense,
				totalizerAfter: {
					reading: totalizerReading.value,
					storeURL: url.value?.storeUrl,
				},
			};

			if (orderStore.getCurrentOrder.category === "DELIVERY") {
				await createTaskValue({
					is_active: true,
					key: "TOTALIZER_AFTER_READING",
					url: url.value ? `${url.value?.storeUrl}` : "",
					value: `${
						totalizerReading.value +
						(orderStore?.getTotalizerBeforeDuringFirstAssetDispense ??
							orderStore?.getLastTotalizerAfterAsTotalizerBefore)
					}`,
					task_id: `${orderStore.getCurrentOrder?.id}`,
					customer_asset_id: `${completedAsset?.id}`,
					quantity_dispensed: totalizerReading.value,
					session_id: dispensingStore.sessionId,
				});

				await updateCustomerOrderCustomerAssetQty(
					orderStore.getCurrentOrder?.customer_order?.id,
					completedAsset.id,
					totalizerReading.value,
				);
			} else {
				await createTaskValue({
					is_active: true,
					key: "TOTALIZER_AFTER_READING",
					url: url.value ? `${url.value?.storeUrl}` : "",
					value: `${totalizerReading.value}`,
					task_id: `${orderStore.getCurrentOrder?.id}`,
					vehicle_id: `${completedAsset?.id}`,
					quantity_dispensed:
						completedAsset.totalizerAfter?.reading -
						completedAsset?.totalizerBefore?.reading,
					session_id: dispensingStore.sessionId,
					customer_asset_id: `${completedAsset?.id}`,
				});
			}

			if (orderStore.getCurrentOrder?.category === "DELIVERY") {
				loaderStore.toggleLoader({type: 'common', state: false});
				orderStore.setDispensedQuantity(0);
				orderStore.setTotalizerBeforeDuringFirstAssetDispense(null);
				orderStore.setTotalizerAfterReading(totalizerReading.value);
				router.replace("/choose-assets/" + orderStore.getCurrentOrder?.id);
			}
		}
	}
};

onMounted(() => {
	window.addEventListener("popstate", backBtnMessage)
	totalizerBeforeReading.value =
		orderStore.getCurrentAssetForDispense?.totalizerBefore?.reading;
});

onUnmounted( async () => {
	await delay(100);
	window.removeEventListener("popstate", backBtnMessage);
})
</script>

<template>
	<div
		class="container flex flex-col items-center justify-start min-h-[calc(100vh-64px)] space-y-4 relative">
		<CompressedPhotoUpload
			:choose-image="chooseImage"
			:button="false"
			name-one="Dispensed Quantity"
			:reading-one-top="true"
			:get-reading="getReading"
			:remove-image="removeImage" />
		<div class="flex flex-col w-full pb-4">
			<button
				type="button"
				class="btn btn-green"
				@click.prevent="
					(e) => {
						nextStep(
							e,
						);
					}
				">
				Proceed
			</button>
		</div>
	</div>
</template>

<style scoped></style>
