import router from "@/router";
import { useOrderStore } from "@/store";

export const restrictInputFunction = (e: any, maxValue?: number) => {
	const inputElement = e.target as HTMLInputElement;
	if (inputElement) {
		if (e.key !== "Backspace") {
			const currentValue = inputElement.value;
			const newValue = currentValue + e.key;
			const regex = /^\d*\.?\d{0,2}$/;
			if (maxValue) {
				if (parseFloat(newValue) > maxValue) {
					e.preventDefault();
				}
			}

			if (
				!regex.test(newValue) ||
				["-", "e"].includes((e as KeyboardEvent).key)
			) {
				e.preventDefault();
			}
		}
	}
};

export const backBtnMessage = () => {
    const orderStore = useOrderStore()
    alert("You will lose all progress");
    router.replace('/choose-assets/' + orderStore.getCurrentOrder?.id)
}
