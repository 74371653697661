<script setup lang="ts">
//imports
import { DateTime } from "luxon";
import Pass from "@/assets/pass.svg?component";
import vehicle from "@/assets/truck-droplet.svg?url";
import { CustomerAssetWithQuantityDispensed } from "@/utils/types";

import { useRouter } from "vue-router";

//store
import { useOrderStore, useDispensingStore} from "@/store";
import { Task_Value } from "@/sdk";
import { addTotalizerBeforeReading } from "@/services/task.service";

type Props = {
	asset: CustomerAssetWithQuantityDispensed;
};

const props = defineProps<Props>();
const router = useRouter();
const orderStore = useOrderStore();
const dispensingStore = useDispensingStore();

const startDispense = () => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(window as any).clarity(
		"set",
		"asset_registration_number",
		props?.asset?.customer_asset?.registration_number ||
			props?.asset?.customer_asset?.description,
	);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(window as any).clarity(
		"set",
		"dispensing_start_time",
		DateTime.now().toFormat("dd/MM/yyyy HH:mm:ss"),
	);
	
	dispensingStore.generateSessionId();
	orderStore.setCurrentAssetForDispense({
		...props.asset.customer_asset,
	});
	const isTotalizerBeforeMoreThanOne =
		orderStore.getCurrentOrder?.task_values.filter(
			(task: Task_Value) => task.key === "TOTALIZER_BEFORE_READING",
		);
	if (orderStore.getCurrentOrder?.is_done_locally) {
		if (orderStore.isCollectOdometerReading) {
			router.push("/odometer");
		} else if (isTotalizerBeforeMoreThanOne && isTotalizerBeforeMoreThanOne.length >= 1) {
			addTotalizerBeforeReading();
			router.push("/totalizer-after-manual");
		} else {
			router.push("/totalizer-before-manual");
		}
	}
};


defineExpose({ startDispense });
//end-of-script
</script>

<template>
	<div
		:class="`mb-2 w-full border border-gray-300 py-4 p-2 flex items-center justify-between relative rounded-md
    ${asset?.quantity_dispensed ? 'bg-gray-100' : 'bg-white'}`">
		<div class="flex items-center gap-x-4">
			<div class="btn btn-green btn-circle btn-sm p-2 asset">
				<img :src="vehicle" class="bg-white" />
			</div>
			<div class="w-full">
				<p class="font-bold w-36 truncate ...">
					{{ asset?.customer_asset?.description }}
				</p>
				<p class="text-xs text-gray-400 capitalize w-36 truncate ...">
					{{ asset?.customer_asset?.name }}
				</p>
				<p class="text-xs text-gray-400 flex space-x-2 items-center">
					Capacity:
					{{ asset?.customer_asset?.capacity }}
					litres
				</p>
			</div>
		</div>
		<div>
			<div
				v-if="asset?.quantity_dispensed"
				disabled
				class="text-sm w-28 flex items-center gap-x-2">
				<p>Asset filled</p>
				<Pass />
			</div>
			<div
				class="flex flex-col items-center justify-between space-y-2">
				<button
					v-if="!asset?.quantity_dispensed"
					@click="startDispense"
					class="btn btn-green w-24 h-6 right-2 !p-0 text-xs">
					Start dispense
				</button>
			</div>
		</div>
	</div>
</template>

<style scoped></style>
